import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch, useSelector } from "../store/store";
import { Article } from "../api/apiClient";
import { fetchArticle } from "../api/articlesService";
import ArticleContent from "./ArticleContent";
import ArticleNotFound from "./ArticleNotFound";

const Article = () => {
  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchArticle(false, Number(id)));
  }, [dispatch]);

  const articles: Article[] = useSelector((state) => state.articles);
  const article = articles.find((a) => a.id === Number(id));

  if (!article || article.status !== "public") {
    return <ArticleNotFound />;
  }

  return (
    <>
      <ArticleContent article={article} detail={true} />
    </>
  );
};

export default Article;
