import React from "react";
import { Outlet } from "react-router-dom";
import { Grid } from "@mui/material";

const Layout = () => {
  return (
    <>
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          "& .markdown": {
            py: 3,
          },
        }}
      >
        <Outlet />
      </Grid>
    </>
  );
};

export default Layout;
