import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { Element } from "html-react-parser";
import hljs from "highlight.js";
import { Box, Card, CardContent, CardMedia, Chip, Grid, Paper, Typography } from "@mui/material";
import "highlight.js/styles/hybrid.css";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Article, Tag } from "../api/apiClient";

const codeParseOptions = {
  replace: (domNode) => {
    if (!(domNode instanceof Element && domNode?.attribs)) {
      return undefined;
    }
    if (domNode.name === "code") {
      const highlightCode = hljs.highlightAuto(domNode.children[0].data);
      const dom = parse(highlightCode.value);

      return <code className="hljs">{dom}</code>;
    }
  },
};

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;

interface ArticleContentProps {
  article: Article;
  detail: boolean;
}

const ArticleContent: React.FC<ArticleContentProps> = ({ article, detail }) => {
  const renderSummary = (article: Article) => {
    return (
      <Card sx={{ pb: 0, boxShadow: 0, borderRadius: 0, display: "flex" }}>
        {article.thumbnail_url && (
          <CardMedia
            component="img"
            sx={{ mr: 2, width: 100, height: 100 }}
            image={article.thumbnail_url}
          />
        )}
        <CardContent sx={{ p: 0, "&:last-child": { p: 0 } }}>
          <Typography variant="body1">{article.description}</Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <Paper elevation={1} sx={{ p: 2, bgcolor: "white" }}>
        <Box sx={{ p: 1 }}>
          <Typography variant="subtitle1">{article.published_date}</Typography>
          <Typography variant="h4">
            <StyledLink to={`/articles/${article.id}`}>{article.title}</StyledLink>
          </Typography>
        </Box>
        {article.tags && article.tags.length > 0 && (
          <Box sx={{ p: 1 }}>
            <Grid container spacing={1}>
              {article.tags.map((tag: Tag) => (
                <React.Fragment key={tag.name}>
                  <Grid item>
                    <Link to={"/articles/tags/" + tag.name}>
                      <Chip label={tag.name} clickable />
                    </Link>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        )}
        <Box sx={{ p: 1 }}>
          {detail === true ? parse(article.body, codeParseOptions) : renderSummary(article)}
        </Box>
      </Paper>
    </>
  );
};

export default ArticleContent;

ArticleContent.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.number.isRequired,
    published_date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    tags: PropTypes.array,
  }).isRequired,
};
