import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch, useSelector } from "../store/store";
import { Article } from "../api/apiClient";
import { fetchArticles } from "../api/articlesService";
import ArticleContent from "./ArticleContent";

const ArticleList = () => {
  const { tag } = useParams();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const parameters = new Map();
    if (tag) {
      parameters.set("tag", tag);
    }
    dispatch(fetchArticles(false, parameters));
  }, [dispatch, tag]);

  const articles: Article[] = useSelector((state) => state.articles);

  const renderArticles = (articleArray: Article[]) => {
    articleArray = articleArray.filter((article) => article.status === "public");
    articleArray = articleArray
      .slice()
      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
    articleArray = articleArray.slice(0, 10);
    return articleArray.map((article) => (
      <React.Fragment key={article.id}>
        <ArticleContent article={article} detail={false} />
        <br />
      </React.Fragment>
    ));
  };

  return <>{renderArticles(articles)}</>;
};

export default ArticleList;
