import React from "react";
import { Route, Routes } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import Article from "./Article";
import ArticleList from "./ArticleList";
import Footer from "./Footer";
import Header from "./Header";
import Layout from "./Layout";
import Sidebar from "./Sidebar";

const Publisher = () => {
  return (
    <>
      <Header />
      <Container maxWidth="lg">
        <main>
          <Grid container spacing={5} sx={{ mt: 3 }}>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route path="/" element={<ArticleList />} />
                <Route path="/:id" element={<Article />} />
                <Route path="/tags/:tag" element={<ArticleList />} />
              </Route>
            </Routes>
            <Sidebar />
          </Grid>
        </main>
      </Container>
      <Footer />
    </>
  );
};

export default Publisher;
