import { createSlice } from "@reduxjs/toolkit";
import { Article } from "../api/apiClient";

export const articlesSlice = createSlice({
  name: "articles",
  initialState: [],
  reducers: {
    addArticle: (state: Article[], action) => {
      const newArticle: Article = action.payload;
      state.push(newArticle);
    },
    removeArticle: (state, action) => {
      const deletedArticleId: number = action.payload;
      const newArticles = state.filter((article: Article) => article.id !== deletedArticleId);
      return newArticles;
    },
    setArticles: (state, action) => action.payload,
    updateArticle: (state: Article[], action) => {
      const newArticle: Article = action.payload;
      const idx = state.findIndex((article) => article.id === newArticle.id);
      state[idx] = newArticle;
    },
  },
});

export const { addArticle, removeArticle, setArticles, updateArticle } = articlesSlice.actions;

export default articlesSlice.reducer;
