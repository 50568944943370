import { HttpStatusCode } from "axios";
import apiClient, { setAuthorizationHeader } from "./apiClient";
import { setUser } from "../store/userSlice";
import { handleAjaxError } from "../helpers/helpers";

export const signin = (userId: string, password: string) => async (dispatch: any) => {
  try {
    const body = { user_id: userId, password: password };
    const response = await apiClient.post(`/api/admin/signin`, body);
    if (response.status !== HttpStatusCode.Ok) {
      throw Error(response.statusText);
    }
    const user = await response.data;
    setAuthorizationHeader("Basic " + user.token);
    dispatch(setUser(user));
  } catch (err: any) {
    handleAjaxError(err);
  }
};
