import React from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Publisher from "./Publisher";
import Editor from "./admin/Editor";

const App = () => (
  <>
    <Routes>
      <Route path="articles/*" element={<Publisher />} />
      <Route path="admin/*" element={<Editor />} />
    </Routes>
    <ToastContainer />
  </>
);

export default App;
