import React from "react";
import { Link } from "react-router-dom";
import { Paper, Box, Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const HeaderLink = styled(Link)`
  color: #fff;
  text-decoration: none;
`;

const Header = () => {
  return (
    <>
      <Paper
        sx={{
          position: "relative",
          color: "#fff",
          mb: 4,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: `url('https://blog.whitewarehouse.net/images/UK.jpg')`,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "rgba(0,0,0,.3)",
          }}
        />
        <Grid container>
          <Grid item md={6}>
            <Box
              sx={{
                position: "relative",
                p: { xs: 3, md: 6 },
                pr: { md: 0 },
              }}
            >
              <Typography component="h3" variant="h4" color="inherit" gutterBottom>
                <HeaderLink to="/articles">whitewarehouse blog</HeaderLink>
              </Typography>
              <Typography variant="subtitle1" color="inherit" paragraph>
                個人的に勉強した技術的なことなどを備忘録として、色々と徒然なるままに
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Header;
