import { HttpStatusCode } from "axios";
import apiClient, { Tag } from "./apiClient";
import { addTag, removeTag, setTags } from "../store/tagsSlice";
import { handleAjaxError } from "../helpers/helpers";

export const createTag = (articleId: number, newTag: Tag) => async (dispatch: any) => {
  try {
    const response = await apiClient.post(`/api/admin/articles/${articleId}/tags`, newTag);
    if (response.status !== HttpStatusCode.Created) {
      throw Error(response.statusText);
    }
    const savedTag = await response.data;
    dispatch(addTag(savedTag));
  } catch (err: any) {
    handleAjaxError(err);
  }
};

export const deleteTag = (articleId: number, tagId: number) => async (dispatch: any) => {
  try {
    const response = await apiClient.delete(`/api/admin/articles/${articleId}/tags/${tagId}`);
    if (response.status !== HttpStatusCode.NoContent) {
      throw Error(response.statusText);
    }
    dispatch(removeTag(tagId));
  } catch (err: any) {
    handleAjaxError(err);
  }
};

export const fetchTags = (isAdmin: boolean, parameters?: any) => async (dispatch: any) => {
  try {
    const url = isAdmin ? "/api/admin/tags" : "/api/tags/";
    const query = createQuery(parameters);
    const res = await apiClient.get(url + query);
    if (res.status !== HttpStatusCode.Ok) {
      throw Error(res.statusText);
    }
    const tags = await res.data;
    dispatch(setTags(tags));
  } catch (err: any) {
    handleAjaxError(err);
  }
};

const createQuery = (parameters?: any) => {
  let query = "";
  if (parameters && parameters.size > 0) {
    parameters.forEach((value: string, key: string) => {
      query = query + key + "=" + value;
    });
    query = "?" + query;
  }
  return query;
};
