import axios from "axios";

const apiServerURL =
  process.env.NODE_ENV === "production"
    ? "https://blog.whitewarehouse.net"
    : "http://localhost:3000";

const apiClient = axios.create({
  baseURL: apiServerURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default apiClient;

export const setAuthorizationHeader = (authHeader: string) => {
  apiClient.defaults.headers.common["Authorization"] = authHeader;
};

export type Tag = {
  id: number;
  name: string;
  article_id: number;
  created_at: string;
  updated_at: string;
};

export type Article = {
  id: number;
  title: string;
  description: string;
  thumbnail_url: string;
  body: string;
  tags: Tag[];
  status: string;
  published_date: string;
  created_at: string;
  updated_at: string;
};
