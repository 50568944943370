import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Alert,
  AlertTitle,
  Button,
  Box,
  Chip,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { AppDispatch, useSelector } from "../../store/store";
import { Article, Tag } from "../../api/apiClient";
import { createArticle, editArticle, fetchArticle } from "../../api/articlesService";
import { createTag } from "../../api/tagsService";
import { isEmptyObject, validateArticle } from "../../helpers/helpers";
import { success } from "../../helpers/notifications";
import AdminArticleNotFound from "./AdminArticleNotFound";

const StyledTextarea = styled(TextareaAutosize)(
  () => `
    width: 100%;
  `,
);

const ArticleForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchArticle(true, Number(id)));
  }, [dispatch]);

  const articles: Article[] = useSelector((state) => state.articles);

  const initialArticleState = useCallback(() => {
    const defaults = {
      id: null,
      title: "",
      description: "",
      thumbnail_url: "",
      body: "",
      tags: [],
      status: "draft",
    };

    const currentArticle = id ? articles.find((a) => a.id === Number(id)) : {};
    return { ...defaults, ...currentArticle };
  }, [articles, id]);

  const [articleForm, setArticleForm] = useState(initialArticleState);
  const [tagName, setTagName] = useState("");
  const [formErrors, setFormErrors] = useState({});

  if (id && !articleForm.id) {
    return <AdminArticleNotFound />;
  }

  const isNew = !articleForm.id ? true : false;
  const title = isNew ? "New Article" : "Edit Article";
  const nextUrl = isNew ? "/admin/articles" : `/admin/articles/${articleForm.id}`;

  useEffect(() => {
    setArticleForm(initialArticleState);
  }, [articles, initialArticleState]);

  const addTag = async (addTag: Tag) => {
    if (!articleForm.id) return;
    await dispatch(createTag(articleForm.id, addTag));
    success("Tag Added");
  };

  const handleAddTag = () => {
    if (tagName) {
      const tag = { name: tagName };
      addTag(tag);
    }
  };

  const handleInputChange = (e) => {
    const { target } = e;
    const { name } = target;

    setArticleForm({ ...articleForm, [name]: target.value });
  };

  const addArticle = async (newArticle) => {
    await dispatch(createArticle(newArticle));
    success("Article Added");
  };

  const updateArticle = async (updatedArticle) => {
    await dispatch(editArticle(updatedArticle));
    success("Article Updated");
  };

  const handleSaveArticle = (e) => {
    e.preventDefault();
    const errors = validateArticle(articleForm);

    if (isEmptyObject(errors)) {
      if (isNew) {
        addArticle(articleForm);
      } else {
        updateArticle(articleForm);
      }
      navigate(nextUrl);
    } else {
      setFormErrors(errors);
    }
  };

  const handleCancel = () => {
    navigate(nextUrl);
  };

  const renderErrors = () => {
    if (isEmptyObject(formErrors)) {
      return null;
    }

    return (
      <>
        <Alert severity="error">
          <AlertTitle>The following errors prohibited the article from being saved:</AlertTitle>
          <List dense={true}>
            {Object.values(formErrors).map((formError, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemText primary={formError} />
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Alert>
      </>
    );
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {renderErrors()}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          Title
          <TextField
            required
            id="title"
            name="title"
            fullWidth
            value={articleForm.title}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          Description
          <StyledTextarea
            required
            id="description"
            name="description"
            minRows={5}
            value={articleForm.description}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          Thumbnail URL
          <TextField
            required
            id="thumbnail_url"
            name="thumbnail_url"
            fullWidth
            value={articleForm.thumbnail_url}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          Body
          <StyledTextarea
            required
            id="body"
            name="body"
            minRows={5}
            value={articleForm.body}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          Tags
          {articleForm.tags && articleForm.tags.length > 0 && (
            <Stack direction="row" spacing={1}>
              {articleForm.tags.map((tag) => (
                <React.Fragment key={tag.id}>
                  <Chip label={tag.name} />
                </React.Fragment>
              ))}
            </Stack>
          )}
          <br />
          <TextField
            id="tagName"
            name="tagName"
            size="small"
            onChange={(e) => setTagName(e.target.value)}
          />
          <Button onClick={handleAddTag}>Add Tag</Button>
        </Grid>
        <Grid item xs={12}>
          Status
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={articleForm.status || "draft"}
            name="status"
            onChange={handleInputChange}
          >
            <FormControlLabel value="draft" control={<Radio />} label="Draft" />
            <FormControlLabel value="public" control={<Radio />} label="Public" />
          </RadioGroup>
        </Grid>
      </Grid>
      <Box>
        <Button onClick={handleSaveArticle}>Save</Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </Box>
    </>
  );
};

export default ArticleForm;
