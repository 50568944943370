import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import { AppDispatch, useSelector } from "../../store/store";
import { Article } from "../../api/apiClient";
import { fetchArticle } from "../../api/articlesService";
import AdminArticleNotFound from "./AdminArticleNotFound";
import AdminArticleContent from "./AdminArticleContent";

const ArticlePreview = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchArticle(false, Number(id)));
  }, [dispatch]);

  const articles: Article[] = useSelector((state) => state.articles);
  const article = articles.find((a) => a.id === Number(id));

  if (!article) {
    return <AdminArticleNotFound />;
  }

  const handleBackButton = () => {
    navigate(`/admin/articles`);
  };

  const handleEditButton = (id: number) => {
    navigate(`/admin/articles/${id}/edit`);
  };

  return (
    <>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        Preview
      </Typography>
      List
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          "& .markdown": {
            py: 3,
          },
        }}
      >
        <AdminArticleContent article={article} detail={false} />
      </Grid>
      <br />
      Detail
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          "& .markdown": {
            py: 3,
          },
        }}
      >
        <AdminArticleContent article={article} detail={true} />
      </Grid>
      <br />
      <Button onClick={() => handleEditButton(article.id)}>Edit</Button>
      <Button onClick={() => handleBackButton()}>Back</Button>
    </>
  );
};

export default ArticlePreview;
