import React from "react";
import { Container, Paper, Typography } from "@mui/material";

const Footer = () => {
  return (
    <>
      <Container maxWidth="lg">
        <Paper elevation={0} sx={{ p: 2, bgcolor: "#f9fafb" }}>
          <Typography variant="body2" color="#9e9e9e">
            Copyright 2023,2024 blog.whitewarehouse.net, All rights reserved.
          </Typography>
        </Paper>
      </Container>
    </>
  );
};

export default Footer;
