import React from "react";
import { Alert } from "@mui/material";

const ArticleNotFound = () => {
  return (
    <>
      <Alert severity="error">Article Not Found</Alert>
    </>
  );
};

export default ArticleNotFound;
