import { configureStore } from "@reduxjs/toolkit";
import { useSelector as originalUseSelector, TypedUseSelectorHook } from "react-redux";

import articlesReducer from "./articlesSlice";
import tagsReducer from "./tagsSlice";
import userReducer from "./userSlice";

export const store = configureStore({
  reducer: {
    articles: articlesReducer,
    tags: tagsReducer,
    user: userReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useSelector: TypedUseSelectorHook<RootState> = originalUseSelector;
