import React from "react";
import parse, { Element } from "html-react-parser";
import hljs from "highlight.js";
import "highlight.js/styles/hybrid.css";
import { Box, Card, CardContent, CardMedia, Chip, Paper, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Article from "../../api/apiClient";

const codeParseOptions = {
  replace: (domNode) => {
    if (!(domNode instanceof Element && domNode?.attribs)) {
      return undefined;
    }
    if (domNode.name === "code") {
      const highlightCode = hljs.highlightAuto(domNode.children[0].data);
      const dom = parse(highlightCode.value);

      return <code className="hljs">{dom}</code>;
    }
  },
};

interface AdminArticleContentProps {
  article: Article;
  detail: boolean;
}

const AdminArticleContent: React.FC<AdminArticleContentProps> = ({ article, detail }) => {
  const renderSummary = (article) => {
    return (
      <Card sx={{ pb: 0, boxShadow: 0, borderRadius: 0, display: "flex" }}>
        {article.thumbnail_url && (
          <CardMedia
            component="img"
            sx={{ mr: 2, width: 100, height: 100 }}
            image={article.thumbnail_url}
          />
        )}
        <CardContent sx={{ p: 0, "&:last-child": { p: 0 } }}>
          <Typography variant="body1">{article.description}</Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <Paper elevation={1} sx={{ p: 2, bgcolor: "white" }}>
        <Box sx={{ p: 1 }}>
          <Typography variant="subtitle1">{article.published_date}</Typography>
          <Typography variant="h4">{article.title}</Typography>
        </Box>
        {article.tags && article.tags.length > 0 && (
          <Box sx={{ p: 1 }}>
            <Stack direction="row" spacing={1}>
              {article.tags.map((tag) => (
                <React.Fragment key={tag.id}>
                  <Chip label={tag.name} clickable />
                </React.Fragment>
              ))}
            </Stack>
          </Box>
        )}
        <Box sx={{ p: 1 }}>
          {detail === true ? parse(article.body, codeParseOptions) : renderSummary(article)}
        </Box>
      </Paper>
    </>
  );
};

export default AdminArticleContent;

AdminArticleContent.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.number.isRequired,
    published_date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    tags: PropTypes.array,
  }).isRequired,
};
