import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  CssBaseline,
  Fab,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "../../store/store";
import ArticlePreview from "./ArticlePreview";
import AdminArticleList from "./AdminArticleList";
import ArticleForm from "./ArticleForm";
import Signin from "./Signin";
import TagList from "./TagList";

const drawerWidth = 240;

const Editor = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  if (!user.token) {
    return <Signin />;
  }

  const handleArticleButton = () => {
    navigate("/admin/articles");
  };

  const handleTagButton = () => {
    navigate("/admin/tags");
  };

  const handleAddButton = () => {
    navigate("/admin/articles/new");
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
          }}
        >
          <Toolbar>
            <Typography variant="h6" noWrap component="div">
              Blog Admin
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar />
          <Divider />
          <List>
            <ListItem key="Articles">
              <ListItemButton onClick={handleArticleButton}>
                <ListItemText primary="Articles" />
              </ListItemButton>
            </ListItem>
            <ListItem key="Tags">
              <ListItemButton onClick={handleTagButton}>
                <ListItemText primary="Tags" />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}>
          <Toolbar />
          <Routes>
            <Route path="/" element={<AdminArticleList />} />
            <Route path="/articles" element={<AdminArticleList />} />
            <Route path="/articles/new" element={<ArticleForm />} />
            <Route path="/articles/:id/edit" element={<ArticleForm />} />
            <Route path="/articles/:id" element={<ArticlePreview />} />
            <Route path="/tags" element={<TagList />} />
          </Routes>
        </Box>
        <Tooltip title="Add Article">
          <Fab
            onClick={handleAddButton}
            color="primary"
            aria-label="add"
            sx={{
              position: "fixed",
              bottom: 20,
              right: 20,
            }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </Box>
    </>
  );
};

export default Editor;
