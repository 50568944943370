import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import { AppDispatch, useSelector } from "../../store/store";
import { Tag } from "../../api/apiClient";
import { deleteTag, fetchTags } from "../../api/tagsService";
import { success } from "../../helpers/notifications";

const TagList = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchTags(true));
  }, [dispatch]);

  const tags: Tag[] = useSelector((state) => state.tags);

  const handleGoToButton = (articleId: number) => {
    navigate(`/admin/articles/${articleId}`);
  };

  const handleDeleteButton = async (articleId: number, tagId: number) => {
    const sure = window.confirm("Are you sure?");
    if (sure) {
      await dispatch(deleteTag(articleId, tagId));
      success("Tag Deleted");
    }
  };

  const renderTags = (tagArray: Tag[]) => {
    tagArray = tagArray
      .slice()
      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

    return tagArray.map((tag) => (
      <React.Fragment key={tag.id}>
        <Card>
          <CardContent>
            <Typography variant="h6">
              {tag.id} : {tag.name}
            </Typography>
          </CardContent>
          <CardActions>
            <Button onClick={() => handleGoToButton(tag.article_id)}>
              Go to article(ID: {tag.article_id})
            </Button>
            <Button onClick={() => handleDeleteButton(tag.article_id, tag.id)}>Delete</Button>
          </CardActions>
        </Card>
        <br />
      </React.Fragment>
    ));
  };

  return <>{renderTags(tags)}</>;
};

export default TagList;
