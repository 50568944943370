import { error } from "./notifications";
import { removeUser } from "../store/userSlice";
import { Article } from "../api/apiClient";

export const validateArticle = (article: Article) => {
  const errors: string[] = [];

  if (article.title === "") {
    errors.push("You must enter a title");
  }
  if (article.body === "") {
    errors.push("You must enter a body");
  }

  return errors;
};

export const isEmptyObject = (obj: object) => Object.keys(obj).length === 0;

export const handleAjaxError = (err: string) => {
  error(err);
  removeUser();
};
