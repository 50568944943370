import { HttpStatusCode } from "axios";
import apiClient, { Article } from "./apiClient";
import { addArticle, removeArticle, setArticles, updateArticle } from "../store/articlesSlice";
import { handleAjaxError } from "../helpers/helpers";

export const createArticle = (newArticle: Article) => async (dispatch: any) => {
  try {
    const response = await apiClient.post("/api/admin/articles", newArticle);
    if (response.status !== HttpStatusCode.Created) {
      throw Error(response.statusText);
    }
    const savedArticle: Article = await response.data;
    dispatch(addArticle(savedArticle));
  } catch (err: any) {
    handleAjaxError(err);
  }
};

export const deleteArticle = (articleId: number) => async (dispatch: any) => {
  try {
    const response = await apiClient.delete(`/api/admin/articles/${articleId}`);
    if (response.status !== HttpStatusCode.NoContent) {
      throw Error(response.statusText);
    }
    dispatch(removeArticle(articleId));
  } catch (err: any) {
    handleAjaxError(err);
  }
};

export const editArticle = (updatedArticle: Article) => async (dispatch: any) => {
  try {
    const response = await apiClient.put(
      `/api/admin/articles/${updatedArticle.id}`,
      updatedArticle,
    );
    if (response.status !== HttpStatusCode.Ok) {
      throw Error(response.statusText);
    }
    const savedArticle = await response.data;
    dispatch(updateArticle(savedArticle));
  } catch (err: any) {
    handleAjaxError(err);
  }
};

export const fetchArticle = (isAdmin: boolean, id: number) => async (dispatch: any) => {
  try {
    const url = isAdmin ? "/api/admin/articles/" : "/api/articles/";
    const res = await apiClient.get(url + id);
    if (res.status !== HttpStatusCode.Ok) {
      throw Error(res.statusText);
    }
    const article = await res.data;
    dispatch(setArticles([article]));
  } catch (err: any) {
    handleAjaxError(err);
  }
};

export const fetchArticles = (isAdmin: boolean, parameters?: any) => async (dispatch: any) => {
  try {
    const url = isAdmin ? "/api/admin/articles/" : "/api/articles/";
    const query = createQuery(parameters);
    const res = await apiClient.get(url + query);
    if (res.status !== HttpStatusCode.Ok) {
      throw Error(res.statusText);
    }
    const articles = await res.data;
    dispatch(setArticles(articles));
  } catch (err: any) {
    handleAjaxError(err);
  }
};

const createQuery = (parameters: any) => {
  let query = "";
  if (parameters && parameters.size > 0) {
    parameters.forEach((value: string, key: string) => {
      query = query + key + "=" + value;
    });
    query = "?" + query;
  }
  return query;
};
