import { createSlice } from "@reduxjs/toolkit";
import { Tag } from "../api/apiClient";

export const tagsSlice = createSlice({
  name: "tags",
  initialState: [],
  reducers: {
    addTag: (state: Tag[], action) => {
      const newTag: Tag = action.payload;
      state.push(newTag);
    },
    removeTag: (state, action) => {
      const deletedTagId: number = action.payload;
      const newTags = state.filter((tag: Tag) => tag.id !== deletedTagId);
      return newTags;
    },
    setTags: (state, action) => action.payload,
  },
});

export const { addTag, removeTag, setTags } = tagsSlice.actions;

export default tagsSlice.reducer;
