import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import { AppDispatch, useSelector } from "../../store/store";
import { Article } from "../../api/apiClient";
import { deleteArticle, fetchArticles } from "../../api/articlesService";
import { success } from "../../helpers/notifications";

const AdminArticleList = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchArticles(true));
  }, [dispatch]);

  const articles: Article[] = useSelector((state) => state.articles);

  const handleDeleteButton = async (articleId: number) => {
    const sure = window.confirm("Are you sure?");
    if (sure) {
      await dispatch(deleteArticle(articleId));
      success("Article Deleted");
    }
  };

  const handleReviewButton = (id: number) => {
    navigate(`/admin/articles/${id}`);
  };

  const handleEditButton = (id: number) => {
    navigate(`/admin/articles/${id}/edit`);
  };

  const renderArticles = (articleArray: Article[]) => {
    articleArray = articleArray
      .slice()
      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

    return articleArray.map((article) => (
      <React.Fragment key={article.id}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
              {article.id} : {article.title}
            </Typography>
            <Typography variant="body1">{article.description}</Typography>
            <Typography sx={{ mt: 1.5 }} variant="body2" color="text.secondary">
              Status: {article.status}
            </Typography>
          </CardContent>
          <CardActions>
            <Button onClick={() => handleReviewButton(article.id)}>Preview</Button>
            <Button onClick={() => handleEditButton(article.id)}>Edit</Button>
            <Button onClick={() => handleDeleteButton(article.id)}>Delete</Button>
          </CardActions>
        </Card>
        <br />
      </React.Fragment>
    ));
  };

  return <>{renderArticles(articles)}</>;
};

export default AdminArticleList;
