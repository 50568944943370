import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { AppDispatch, useSelector } from "../store/store";
import { fetchTags } from "../api/tagsService";
import { Tag } from "../api/apiClient";

const Sidebar = () => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTags(false));
  }, [dispatch]);

  const tags: Tag[] = useSelector((state) => state.tags);

  return (
    <>
      <Grid item xs={12} md={4}>
        <Paper elevation={0} sx={{ p: 2, bgcolor: "#f9fafb" }}>
          <Typography variant="h6" gutterBottom>
            Profile
          </Typography>
          <Card elevation={0} sx={{ p: 0, bgcolor: "#f9fafb" }}>
            <CardHeader
              avatar={<Avatar src="https://blog.whitewarehouse.net/images/profile-icon.png" />}
              title="whitewarehouse"
              sx={{ p: 0 }}
            />
            <CardContent sx={{ p: "8px 0" }}>
              <Typography variant="body2">
                とある企業でバックエンドエンジニアとして働いています。
                <br />
                ブログを続けることを目標として無理のない範囲でがんばります。
              </Typography>
            </CardContent>
          </Card>
        </Paper>
        <Paper elevation={0} sx={{ p: 2, bgcolor: "#f9fafb" }}>
          <Typography variant="h6" gutterBottom>
            Category
          </Typography>
          {tags.length > 0 && (
            <Grid container spacing={1}>
              {tags.map((tag) => (
                <React.Fragment key={tag.name}>
                  <Grid item>
                    <Link to={"/articles/tags/" + tag.name}>
                      <Chip label={tag.name} clickable />
                    </Link>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          )}
        </Paper>
      </Grid>
    </>
  );
};

export default Sidebar;
